/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import TextInput from "../form/inputs/text-input/text-input";
import { chooseFile, readLocalFile } from "../../utils/utils";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export default function File(props?: {
  filepath?: string;
  label?: string;
  onSelect?: any;
  type?: string;
  minHeight?: number;
  maxWidth?: number;
}) {
  const [file, setfile]: any = useState("");
  const [fileURL, setfileURL]: any = useState("");
  const [fileName, setfileName]: any = useState("");
  const [src] = useState(props?.filepath);
  async function onSelect() {
    const files: any = await chooseFile({
      patternFile: props?.type == "pdf" ? [".pdf", ".PDF"] : [".jpeg", ".png", ".webp"],
      multiple: false,
    });
    setfileURL(URL.createObjectURL(files[0]));
    setfileName(files[0].name);
    const file = await readLocalFile(files[0]);
    setfile(file);
    if (props?.onSelect) props.onSelect(files[0]);
  }
  const isImg = ["png", "jpeg", "webp", "jpg"].indexOf(src?.split(".")?.reverse()[0]?.toLowerCase() as any) != -1;
  const isPDF = ["pdf", "PDF"].indexOf(src?.split(".")?.reverse()[0]?.toLowerCase() as any) != -1;
  return (
    <>
      <div className="col-sm-12 col-md-12 mt-2 ">
        <div className="row">
          <div className={"col-12"} onClick={onSelect}>
            <TextInput
              value={fileName || src}
              containerStyle={{ pointerEvents: "none" }}
              label={props?.label}
              placeHolder="Sélectionner un fichier"
              rightIcon={
                fileName || src ? <img src="/images/edit.svg" alt="" /> : <img src="/images/edit.svg" alt="" />
              }
            />
          </div>
        </div>
      </div>

      <div
        className="col-sm-12 col-md-12 mt-2 p-2 "
        style={{
          backgroundColor: "#F0F0F0",
          minHeight: props?.minHeight || 330,
          maxHeight: 600,
          maxWidth: props?.maxWidth || 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {props?.filepath || file ? (
          <a target="_blank" href={fileURL || process.env.REACT_APP_SERVER_URL + "/" + props?.filepath}>
            {(props?.type == "pdf" && isImg && !file) || (props?.type != "pdf" && (file || src)) ? (
              <img
                style={{ width: "100%", maxHeight: "100%" }}
                alt=""
                src={file || process.env.REACT_APP_SERVER_URL + "/" + src}
              />
            ) : (
              props?.type == "pdf" &&
              (fileURL || (src && isPDF)) && (
                <Viewer fileUrl={fileURL || process.env.REACT_APP_SERVER_URL + "/" + src} />
              )
            )}
          </a>
        ) : null}
      </div>
    </>
  );
}
