import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
export default function Sidebarr({ routes }: any) {
  const [collapsed, setCollapsed] = useState(false);
  const { appInfos } = useAppContext();
  return (
    <OffCanva>
      <Sidebar toggled={false}  collapsed={collapsed} className="sidebar">
        <div className="container">
          <Menu className="menu">
            <div className="sidebartop" style={{position:'relative',width:'100%'}}>
              <Link style={{ width: "100%" }} to="/">
                <div className="logo">
                  <img src="/images/logo.png" alt="" />
                </div>
              </Link>
              <div onClick={() => setCollapsed((old) => !old)} className="collapse">
                <img
                  style={{ width: 20, height: 20, transform: !collapsed ? "rotate(0deg)" : "rotate(180deg)" }}
                  src={"/images/rightchev.svg"}
                  alt=""
                />
              </div>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
            </div>
            {routes
              .filter((elt: any) => !elt.hide)
              .map((route: any, index: any) => {
                const emplyee = route.permission && appInfos.user.type == "Employee";
                const perms = appInfos.user?.permissions && appInfos.user?.permissions[route.permission];
                const inactive = emplyee && !perms?.find((elt: any) => elt == "read");
                const nestedActive = route?.nested
                  ?.filter((elt: any) => !elt.hide && elt.permission != false)
                  ?.filter((nested: any, inde1: any) => {
                    const emplyee = nested.permission && appInfos.user.type == "Employee";
                    const perms = appInfos.user?.permissions && appInfos.user?.permissions[nested.permission];
                    const disablenested = emplyee && !perms?.find((elt: any) => elt == "read");
                    return !disablenested;
                  });
                return (
                  !inactive &&
                  (!route?.nested?.length || nestedActive?.length > 0) && (
                    <SidebarMenu
                      single={!route?.nested?.filter((elt: any) => !elt.hide && elt.permission != false).length}
                      key={index}
                      label={route.label}
                      route={route.route}
                      icon={route.icon}
                    >
                      {nestedActive?.map((nested: any, inde1: any) => {
                        return (
                          <SidebarItem
                            key={index + "" + inde1}
                            route={route.route + "/" + nested.route}
                            label={nested.label}
                          />
                        );
                      })}
                    </SidebarMenu>
                  )
                );
              })}
          </Menu>
          <div className="userbg">
            <SidebarUser collapsed={collapsed} />
          </div>
        </div>
      </Sidebar>
    </OffCanva>
  );
}
function SidebarUser({ collapsed }: any) {
  const { appInfos, setAppInfos } = useAppContext();
  const navigate = useNavigate();
  function onLogout() {
    setAppInfos({ user: null, token: null });
    navigate("/login");
  }
  return (
    <div className="dropdown">
      <Link
        to="/"
        className="d-flex align-items-center link-light text-decoration-none dropdown-toggle"
        id="dropdownUser2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ width: "100%" }}
      >
        <img src="/images/avatar.png" alt="" width="40" height="40" className="rounded-circle me-2" />
        <strong className="username">
          {collapsed ? "" : (appInfos.user.prenom || "") + " " + (appInfos.user.nom || "")}
        </strong>
      </Link>

      <ul
        style={{ width: "100%", alignSelf: "center", minWidth: 200 }}
        className="dropdown-menu text-small shadow pt-4 pb-4"
        aria-labelledby="dropdownUser2"
      >
        <li>
          <div style={{ display: "flex", backgroundColor: "white", alignItems: "center", justifyContent: "center" }}>
            <img style={{ marginLeft: 10 }} src="/images/avatar.png" alt="" width="50" height="50" />
            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {(appInfos.user.prenom || "") + " " + (appInfos.user.nom || "")}
            </span>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider divider" />
        </li>
        <li>
          <Link className="dropdown-item userlink" to={"/profil"}>
            Mon profil
          </Link>
        </li>
        <li>
          <button onClick={onLogout} className="dropdown-item userlink">
            Se déconnecter
          </button>
        </li>
      </ul>
    </div>
  );
}
function SidebarMenu(props: any) {
  const location = useLocation();
  const [toggle, settoggle] = useState(false);
  const active =
    props.route == "/" ? props.route == location.pathname : props.route && location.pathname.indexOf(props.route) != -1;
  const single = props.single;
  useEffect(() => {
    if (!active) settoggle(false);
  }, [active]);
  return (
    <SubMenu
      open={toggle}
      className={`submenu  ${single ? "submenu-single" : ""}  ${toggle ? "submenu-active" : "submenu-inactive"}`}
      component={props.route && single ? <Link to={props.route} /> : null}
      onClick={() => settoggle((old) => !old)}
      {...props}
      icon={
        props.icon && (
          <span>
            <img className="icon" src={props.icon} alt="" />
          </span>
        )
      }
    >
      {props.children}
    </SubMenu>
  );
}

function SidebarItem(props: any) {
  const location = useLocation();
  const active = props.route && location.pathname.indexOf(props.route) != -1;
  return (
    <MenuItem
      component={props.route && <Link to={props.route} />}
      className={`menuitem ${active ? "menuitem-active" : ""}`}
      {...props}
      icon={props.icon && <img className="icon" src={props.icon} alt="" />}
    >
      <span> • &nbsp; {props.label || props.children}</span>
    </MenuItem>
  );
}

function OffCanva(props: any) {
  return (
    <div>
      <div className="togglebar">
        <div className="d-flex">
          <a
            className="toggle"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            <img style={{ width: 24, marginRight: 20 }} src="/images/menu.svg" alt="" />
          </a>
          <Link to="/">
            <div className="logo">
              <img style={{ width: 80 }} src="/images/logo.png" alt="" />
            </div>
          </Link>
        </div>
        <div className="usersm ">
          <SidebarUser />
        </div>
      </div>
      <div className="offcanvas " tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-body">{props.children}</div>
      </div>
      <div className="sidecontent" tabIndex={-1}>
        {props.children}
      </div>
    </div>
  );
}
